import JlinkRequest from '@/common/global/JlinkRequest'

// 分页查询航线工作记录
export function getFlightTaskListByPage (data: ReqFlightTaskListByPage['Request']) {
  return JlinkRequest.request<ReqFlightTaskListByPage>('flighttask/listByPage', 'GET', data, true)
}

// 删除航线工作记录
export function deleteFlightTask (data: ReqDeleteFlightTask['Request']) {
  return JlinkRequest.request<ReqDeleteFlightTask>('flighttask/delete', 'DELETE', data, true)
}

// 取消航线工作
export function cancelFlightTask (data: ReqCancelFlightTask['Request']) {
  return JlinkRequest.request<ReqCancelFlightTask>('flighttask/cancel', 'PUT', data, true)
}

// 获取航线工作文件上传最高权限
export function flightTaskUploadMediaHighestPriority (data: ReqFlightTaskUploadMediaHighestPriority['Request']) {
  return JlinkRequest.request<ReqFlightTaskUploadMediaHighestPriority>('flighttask/uploadMediaHighestPriority', 'PUT', data, true)
}

// 根据飞机查询航线工作记录
export function getFlightTaskListByDroneId (data: ReqFlightTaskListByDroneId['Request']) {
  return JlinkRequest.request<ReqFlightTaskListByDroneId>('flighttask/listByDroneId', 'GET', data, true)
}

// 自动执行航线工作
export function autoExecuteFlightTask (data: ReqFlightTaskAutoExecute['Request']) {
  return JlinkRequest.request<ReqFlightTaskAutoExecute>('flighttask/autoExecute', 'POST', data, true)
}

// 一键返航
export function returnHome (data: ReqReturnHomeFlightTask['Request']) {
  return JlinkRequest.request<ReqReturnHomeFlightTask>('flighttask/return', 'POST', data, true)
}

// 中断返航后，飞机会退出航线模式，注意：此时中断返航，飞机会悬停
export function returnHomeCancel (data: ReqReturnHomeCancelFlightTask['Request']) {
  return JlinkRequest.request<ReqReturnHomeCancelFlightTask>('flighttask/returnCancel', 'POST', data, true)
}

// 暂停航线工作
export function pauseFlightTask (data: ReqPauseFlightTask['Request']) {
  return JlinkRequest.request<ReqPauseFlightTask>('flighttask/pause', 'POST', data, true)
}

// 恢复执行航线工作
export function recoveryFlightTask (data: ReqRecoveryFlightTask['Request']) {
  return JlinkRequest.request<ReqRecoveryFlightTask>('flighttask/recovery', 'POST', data, true)
}
