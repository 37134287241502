import { UserModule } from '@/store/User'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkRequest from '@/common/global/JlinkRequest'

export async function getAssignmentsMsgList (data: ReqGetAssignmentsMessageList['Request'], showLoading: boolean = true) {
  const result = await JlinkRequest.request<ReqGetAssignmentsMessageList>('message/assignments/listByPage', 'GET', data, showLoading)
  result.data.forEach(i => {
    i.own = i.sender?.userId === UserModule.user?.userId
    i.formatTime = JlinkUtils.date.parseTime(i.createTime)
  })
  return result
}

export async function assignmentsMessageSend (data: ReqAssignmentsMessageSend['Request'], showLoading: boolean = true) {
  return await JlinkRequest.request<ReqAssignmentsMessageSend>('message/assignments/send', 'POST', data, showLoading)
}
