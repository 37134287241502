import JlinkRequest from '@/common/global/JlinkRequest'

export function startLiveStream (data:ReqStartLiveStream['Request']) {
  return JlinkRequest.request<ReqStartLiveStream>('streams/start', 'PUT', data, true)
}

export function stopLiveStream (data:ReqStopLiveStream['Request']) {
  return JlinkRequest.request<ReqStopLiveStream>('streams/stop', 'PUT', data, true)
}

export function setLiveQuality (data:ReqSetLiveQuality['Request']) {
  return JlinkRequest.request<ReqSetLiveQuality>('streams/quality', 'PUT', data, true)
}

export function setLiveLens (data:ReqSetLiveLens['Request']) {
  return JlinkRequest.request<ReqSetLiveLens>('streams/liveLensChange', 'PUT', data, true)
}
