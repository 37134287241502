
import JlinkRequest from '@/common/global/JlinkRequest'
import { AxiosProgressEvent } from 'axios'

export function waylineUpload (data: ReqWaylineUpload['Request'], onUploadProgress?: (e:AxiosProgressEvent) => void) {
  return JlinkRequest.request<ReqWaylineUpload>('wayline/upload', 'POST', data, true, onUploadProgress)
}

export function waylineListQuery (data: ReqWaylineListQuery['Request']) {
  return JlinkRequest.request<ReqWaylineListQuery>('wayline/listByQuery', 'GET', data, true)
}

export function waylineDelete (data: ReqWaylineDelete['Request']) {
  return JlinkRequest.request<ReqWaylineDelete>('wayline/delete', 'DELETE', data, true)
}

export function waylineGet (data: ReqWaylineGet['Request']) {
  return JlinkRequest.request<ReqWaylineGet>('wayline/getWayline', 'GET', data, true)
}

export function makeNewWayline (data: ReqMakeNewWayline['Request']) {
  // @ts-ignore
  return JlinkRequest.request<ReqMakeNewWayline>('wayline/makeNewWayline', 'POST', { data: JSON.stringify(data) }, true)
}

export function parseWaylineInfo (data: ReqGetWaylineInfo['Request']) {
  return JlinkRequest.request<ReqGetWaylineInfo>('wayline/parse', 'GET', data, true)
    .then(res => {
      if (res.common.templateType === 'mapping2d') {
        const waylineValue = (res as TemplateMapping2DInfo)
        waylineValue.common.shootSurfaceHeight = waylineValue.height - waylineValue.common.globalShootHeight
      }

      if (res.common.templateType === 'waypoint') {
        const waylineValue = (res as TemplateWaypointInfo)
        waylineValue.points.forEach(item => {
          if (!item.height) {
            item.height = res.height
            item.useGlobalHeight = true
          }
          if (!item.waypointSpeed) {
            item.waypointSpeed = res.common.autoFlightSpeed
            item.useGlobalSpeed = true
          }
          if (!item.waypointTurnMode) {
            item.waypointTurnMode = waylineValue.globalWaypointTurnMode
            item.useGlobalWaypointTurnMode = true
          }
          if (!item.waypointHeadingMode) {
            item.waypointHeadingMode = waylineValue.globalWaypointHeadingMode
            item.useGlobalWaypointHeadingMode = true
          }
          if (!item.waypointHeadingPathMode) {
            item.waypointHeadingPathMode = waylineValue.globalWaypointHeadingPathMode
            item.useGlobalWaypointHeadingPathMode = true
          }
        })
      }
      return res
    })
}

export function editWaylineInfo (data: ReqEditWaylineInfo['Request']) {
  // @ts-ignore
  return JlinkRequest.request<ReqEditWaylineInfo>('wayline/updateWayline', 'PUT', { data: JSON.stringify(data) }, true)
}

export function parseTaskWaylineInfo (data: ReqGetTaskWaylineInfo['Request']) {
  return JlinkRequest.request<ReqGetTaskWaylineInfo>('wayline/parseTaskWayline', 'GET', data, true)
    .then(res => {
      if (res.common.templateType === 'waypoint') {
        const waylineValue = (res as TemplateWaypointInfo)
        waylineValue.points.forEach(item => {
          if (!item.height) {
            item.height = res.height
            item.useGlobalHeight = true
          }
          if (!item.waypointSpeed) {
            item.waypointSpeed = res.common.autoFlightSpeed
            item.useGlobalSpeed = true
          }
          if (!item.waypointTurnMode) {
            item.waypointTurnMode = waylineValue.globalWaypointTurnMode
            item.useGlobalWaypointTurnMode = true
          }
          if (!item.waypointHeadingMode) {
            item.waypointHeadingMode = waylineValue.globalWaypointHeadingMode
            item.useGlobalWaypointHeadingMode = true
          }
          if (!item.waypointHeadingPathMode) {
            item.waypointHeadingPathMode = waylineValue.globalWaypointHeadingPathMode
            item.useGlobalWaypointHeadingPathMode = true
          }
        })
      }
      return res
    })
}
