import JlinkRequest from '@/common/global/JlinkRequest'

export function miniprogramLoginByToken (data: ReqMiniProgramLoginByToken['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqMiniProgramLoginByToken>('user/miniprogramLoginByToken', 'GET', data, showLoading)
}

export function miniprogramLogin (data: ReqMiniProgramLogin['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqMiniProgramLogin>('user/miniprogramLogin', 'GET', data, showLoading)
}

export function djCloudLogin (data: ReqDjiCloudLogin['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqDjiCloudLogin>('user/djiCloudLogin', 'GET', data, showLoading)
}

export function loginByNPRKey (data: ReqLoginByNPRKey['Request']) {
  return JlinkRequest.request<ReqLoginByNPRKey>('user/nprLogin', 'GET', data)
}

export function login (data: ReqLogin['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqLogin>('user/webLogin', 'GET', data, showLoading)
}

export function logout () {
  return JlinkRequest.request<ReqLoginOut>('user/logout', 'GET', undefined)
}

export function changePwd (data: ReqChangePwd['Request']) {
  return JlinkRequest.request<ReqChangePwd>('user/updatePwd', 'PUT', data)
}

export function getAuthCode (data: ReqGetAuthCode['Request']) {
  return JlinkRequest.request<ReqGetAuthCode>('system/getSmsAuthCode', 'GET', data)
}

export function resetPwd (data: ReqResetPassword['Request']) {
  return JlinkRequest.request<ReqResetPassword>('user/resetPwd', 'PUT', data)
}

export function modifyUser (data: ReqUserUpdate['Request']) {
  return JlinkRequest.request<ReqUserUpdate>('user/modify', 'PUT', data)
}

export function getMemberList (data: ReqGetMemberList['Request']) {
  return JlinkRequest.request<ReqGetMemberList>('user/getMemberList', 'GET', data)
}

export function uploadFlyLicense (data: ReqUploadFlyLicense['Request']) {
  return JlinkRequest.request<ReqUploadFlyLicense>('user/uploadFlyLicense', 'POST', data)
}

export function changeRoleAsAdmin (data: ReqChangeRoleAsAdmin['Request']) {
  return JlinkRequest.request<ReqChangeRoleAsAdmin>('user/changeRoleAsAdmin', 'PUT', data)
}

export function deleteMember (data: ReqDeleteMember['Request']) {
  return JlinkRequest.request<ReqDeleteMember>('user/deleteUser', 'DELETE', data)
}

export function addMember (data: ReqAddMember['Request']) {
  return JlinkRequest.request<ReqAddMember>('user/addMember', 'POST', data)
}

export function getMemberListBaseAll (data: ReqGetMemberListBaseAll['Request']) {
  return JlinkRequest.request<ReqGetMemberListBaseAll>('user/getMemberListBaseAll', 'GET', data)
}
