import JlinkRequest from '@/common/global/JlinkRequest'

export function addAssignments (data: ReqAddAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqAddAssignments>('assignments/addAssignments', 'POST', data, showLoading)
}

export function editAssignments (data: ReqEditAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqEditAssignments>('assignments/editAssignments', 'PUT', data, showLoading)
}

export function getAssignmentsList (data: ReqGetAssignmentsList['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqGetAssignmentsList>('assignments/getAssignmentsList', 'GET', data, showLoading)
}

export function getAssignmentsHistoryList (data: ReqGetAssignmentsHistoryList['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqGetAssignmentsHistoryList>('assignments/getAssignmentsHistory', 'GET', data, showLoading)
}

export function deleteAssignments (data: ReqDeleteAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqDeleteAssignments>('assignments/deleteAssignments', 'DELETE', data, showLoading)
}

export function getAssignmentsDetail (data: ReqGetAssignmentsDetail['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqGetAssignmentsDetail>('assignments/getAssignmentsDetail', 'GET', data, showLoading)
}

export function getAssignmentsBase (data: ReqGetAssignmentsBase['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqGetAssignmentsBase>('assignments/getAssignmentsBase', 'GET', data, showLoading)
}

export function startAssignments (data: ReqStartAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqStartAssignments>('assignments/start', 'PUT', data, showLoading)
}

export function endAssignments (data: ReqEndAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqEndAssignments>('assignments/end', 'PUT', data, showLoading)
}

export function enterAssignments (data: ReqEnterAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqEnterAssignments>('assignments/enter', 'PUT', data, showLoading)
}

export function leaveAssignments (data: ReqLeaveAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqLeaveAssignments>('assignments/leave', 'PUT', data, showLoading)
}

export function getAssignmentsFilesInfo (data: ReqAssignmentsFilesInfo['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqAssignmentsFilesInfo>('assignments/filesInfo', 'GET', data, showLoading)
}

export function deleteAssignmentsDocument (data: ReqDeleteAssignmentsDocument['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqDeleteAssignmentsDocument>('assignments/deleteDocuments', 'DELETE', data, showLoading)
}

export function getAssignmentsSecretKey (data: ReqShareAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqShareAssignments>('assignments/share', 'POST', data, showLoading)
}

export function getAssignmentsShare (data: ReqLoadShareAssignments['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqLoadShareAssignments>('assignments/loadShare', 'GET', data, showLoading)
}
