import JlinkRequest from '@/common/global/JlinkRequest'

export function makeSecretKey () {
  return JlinkRequest.request<ReqMakeSecretKey>('company/makeSecretKey', 'GET', undefined)
}

export function addCompany (data: ReqAddRootCompany['Request']) {
  return JlinkRequest.request<ReqAddRootCompany>('company/addRootCompany', 'POST', data)
}

export function addChildCompany (data: ReqAddChildCompany['Request']) {
  return JlinkRequest.request<ReqAddChildCompany>('company/addChildCompany', 'POST', data)
}

export function getAllCompany (data: ReqGetAllCompany['Request']) {
  return JlinkRequest.request<ReqGetAllCompany>('company/all', 'GET', data)
}

export function updateCompany (data: ReqUpdateCompany['Request']) {
  return JlinkRequest.request<ReqUpdateCompany>('company/modify', 'PUT', data, false)
}

export function deleteCompany (data: ReqDeleteCompany['Request']) {
  return JlinkRequest.request<ReqDeleteCompany>('company/delete', 'DELETE', data)
}

export function getCompany (data: ReqGetCompany['Request']) {
  return JlinkRequest.request<ReqGetCompany>('company/get', 'GET', data)
}

export function getCompanyDetail (data: ReqGetCompanyDetail['Request']) {
  return JlinkRequest.request<ReqGetCompanyDetail>('company/detail', 'GET', data)
}

export function makeHookCallback (data: ReqMakeHookCallback['Request']) {
  return JlinkRequest.request<ReqMakeHookCallback>('company/hookCallback', 'PUT', data)
}

export function makeHookClear (data: ReqMakeHookClear['Request']) {
  return JlinkRequest.request<ReqMakeHookClear>('company/hookClear', 'PUT', data)
}

export function setAiLiveAble (data: ReqSetAiLiveAble['Request']) {
  return JlinkRequest.request<ReqSetAiLiveAble>('company/setAiLive', 'PUT', data)
}
