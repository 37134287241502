import JlinkRequest from '@/common/global/JlinkRequest'

export function modifyDroneParts (data: ReqDronePartsUpdate['Request']) {
  return JlinkRequest.request<ReqDronePartsUpdate>('droneParts/modify', 'PUT', data)
}

export function getDronePartsListByPage (data: ReqGetDronePartsListByPage['Request']) {
  return JlinkRequest.request<ReqGetDronePartsListByPage>('droneParts/getListByPage', 'GET', {
    searchKey: data.searchKey,
    page: data.page,
    size: data.size,
    companyId: data.companyId
  })
}
export function getDronePartsDetail (data: ReqGetDronePartsDetail['Request']) {
  return JlinkRequest.request<ReqGetDronePartsDetail>('droneParts/getDetail', 'GET', data)
}

export function getDronePartsListBaseAll (data: ReqGetDronePartsListBaseAll['Request']) {
  return JlinkRequest.request<ReqGetDronePartsListBaseAll>('droneParts/getListBaseAll', 'GET', data)
}

export function addDroneParts (data: ReqAddDroneParts['Request']) {
  return JlinkRequest.request<ReqAddDroneParts>('droneParts/add', 'POST', data)
}

export function deleteDroneParts (data: ReqDeleteDroneParts['Request']) {
  return JlinkRequest.request<ReqDeleteDroneParts>('droneParts/delete', 'DELETE', data)
}

export function addSituationJournal (data: ReqAddDronePartsSituationJournal['Request']) {
  return JlinkRequest.request<ReqAddDronePartsSituationJournal>('droneParts/situation/addJournal', 'POST', data)
}

export function deleteSituationJournal (data: ReqDeleteDronePartsSituationJournal['Request']) {
  return JlinkRequest.request<ReqDeleteDronePartsSituationJournal>('droneParts/situation/deleteJournal', 'DELETE', data)
}

export function deleteSituation (data: ReqDeleteDroneSituation['Request']) {
  return JlinkRequest.request<ReqDeleteDronePartsSituation>('droneParts/deleteSituation', 'DELETE', data)
}

export function addSituation (data: ReqAddDronePartsSituation['Request']) {
  return JlinkRequest.request<ReqAddDronePartsSituation>('droneParts/addSituation', 'POST', data)
}

export function processDronePartsSituation (data: ReqProcessDronePartsSituation['Request']) {
  return JlinkRequest.request<ReqProcessDronePartsSituation>('droneParts/processSituation', 'PUT', data)
}
