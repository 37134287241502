import JlinkRequest from '@/common/global/JlinkRequest'

// 一键起飞
export function takeOffToPoint (data: ReqTakeOffToPoint['Request']) {
  return JlinkRequest.request<ReqTakeOffToPoint>('drc/takeOffToPoint', 'POST', data, true)
}

// 飞向目标点
export function flyToPoint (data: ReqFlyToPoint['Request']) {
  return JlinkRequest.request<ReqFlyToPoint>('drc/flyToPoint', 'POST', data, true)
}

// 飞向目标点停止
export function flyToPointStop (data: ReqFlyToPointStop['Request']) {
  return JlinkRequest.request<ReqFlyToPointStop>('drc/flyToPointStop', 'POST', data, true)
}

// 负载控制-切换相机模式
export function cameraModeSwitch (data: ReqCameraModeSwitch['Request']) {
  return JlinkRequest.request<ReqCameraModeSwitch>('drc/cameraModeSwitch', 'POST', data, false)
}

// 负载控制-单拍
export function cameraPhotoTake (data: ReqCameraPhotoTake['Request']) {
  return JlinkRequest.request<ReqCameraPhotoTake>('drc/cameraPhotoTake', 'POST', data, true)
}

// "更改目标点(一键起飞过程中更改目标点)"
export function updateToPoint (data: ReqUpdateToPoint['Request']) {
  return JlinkRequest.request<ReqUpdateToPoint>('drc/updateToPoint', 'POST', data, false)
}

// 照片存储设置
export function photoStorageSet (data:ReqPhotoStorageSet['Request']) {
  return JlinkRequest.request<ReqPhotoStorageSet>('drc/photoStorageSet', 'POST', data, false)
}

// "负载控制-停止拍照,停止拍照指令，目前仅支持全景拍照模式"
export function cameraPhotoTakeStop (data:ReqCameraPhotoTakeStop['Request']) {
  return JlinkRequest.request<ReqCameraPhotoTakeStop>('drc/cameraPhotoStop', 'POST', data, false)
}

// 视频存储设置
export function videoStorageSet (data:ReqVideoStorageSet['Request']) {
  return JlinkRequest.request<ReqVideoStorageSet>('drc/videoStorageSet', 'POST', data, false)
}

// "负载控制—画面拖动控制"
export function cameraScreenDrag (data: ReqCameraScreenDrag['Request']) {
  return JlinkRequest.request<ReqCameraScreenDrag>('drc/cameraScreenDrag', 'POST', data, true)
}

// 负载控制-开始录像
export function cameraStartRecording (data: ReqCameraRecordingStart['Request']) {
  return JlinkRequest.request<ReqCameraRecordingStart>('drc/cameraRecordingStart', 'POST', data, true)
}

// 负载控制-停止录像
export function cameraStopRecording (data: ReqCameraRecordingStop['Request']) {
  return JlinkRequest.request<ReqCameraRecordingStop>('drc/cameraRecordingStop', 'POST', data, true)
}

// 负载控制-变焦
export function cameraFocalLengthSet (data: ReqCameraFocalLengthSet['Request']) {
  return JlinkRequest.request<ReqCameraFocalLengthSet>('drc/cameraFocalLengthSet', 'POST', data, false)
}

// 负载控制-重置云台
export function gimbalReset (data: ReqGimbalReset['Request']) {
  return JlinkRequest.request<ReqGimbalReset>('drc/gimbalReset', 'POST', data, true)
}

// 负载控制-双击成为 AIM 在相机镜头内，双击目标点，该目标点会成为镜头中心点
export function cameraAim (data: ReqCameraAim['Request']) {
  return JlinkRequest.request<ReqCameraAim>('drc/cameraAim', 'POST', data, false)
}

export function drcEnter (data: ReqDrcEnter['Request']) {
  return JlinkRequest.request<ReqDrcEnter>('drc/enter', 'POST', data, true)
}

export function drcExit (data: ReqDrcExit['Request']) {
  return JlinkRequest.request<ReqDrcExit>('drc/exit', 'POST', data, true)
}
