import JlinkRequest from '@/common/global/JlinkRequest'


export function waylineJobAdd (data: ReqWaylineJobAdd['Request']) {
  return JlinkRequest.request<ReqWaylineJobAdd>('waylineJob/createJob', 'POST', data, true)
}

export function waylineJobUpdate (data: ReqWaylineJobUpdate['Request']) {
  return JlinkRequest.request<ReqWaylineJobUpdate>('waylineJob/updateJob', 'PUT', data, true)
}

export function waylineJobPublish (data: ReqWaylineJobPublish['Request']) {
  return JlinkRequest.request<ReqWaylineJobPublish>('waylineJob/publish', 'PUT', data, true)
}

export function waylineJobRevoke (data: ReqWaylineJobRevoke['Request']) {
  return JlinkRequest.request<ReqWaylineJobRevoke>('waylineJob/revoke', 'PUT', data, true)
}

export function waylineJoblistByPage (data: ReqWaylineJobListByQuery['Request']) {
  return JlinkRequest.request<ReqWaylineJobListByQuery>('waylineJob/listByQuery', 'GET', data, true)
}
export function waylineJobGet (data: ReqWaylineJobGet['Request']) {
  return JlinkRequest.request<ReqWaylineJobGet>('waylineJob/get', 'GET', data, true)
}

export function waylineJoblistDelete (data: ReqWaylineJobDelete['Request']) {
  return JlinkRequest.request<ReqWaylineJobDelete>('waylineJob/delete', 'DELETE', data, true)
}
