import JlinkTransform from '@/common/global/JlinkTransform'
import JlinkRequest from '@/common/global/JlinkRequest'

export function getUploadFilesInAssignments (data: ReqGetUploadFilesInAssignments['Request']) {
  return JlinkRequest.request<ReqGetUploadFilesInAssignments>('uploads/getUploadFilesInAssignments', 'GET', data, true)
    .then((res) => {
      return { data: res.data.map(i => JlinkTransform.makeUploadFileWrapper(i)), totalCount: res.totalCount }
    })
}

export function getUploadFilesInTask (data: ReqGetUploadFilesInTask['Request']) :Promise<UploadFileWrapperVo[]> {
  return JlinkRequest.request<ReqGetUploadFilesInTask>('uploads/getUploadFilesInTask', 'GET', data, true)
    .then((res) => {
      return res.map(i => JlinkTransform.makeUploadFileWrapper(i))
    })
}

export function renameUploadFile (data: ReqRenameUploadFile['Request']) {
  return JlinkRequest.request<ReqRenameUploadFile>('uploads/rename', 'PUT', data, true)
}

export function deleteUploadFiles (data: ReqDeleteUploadFile['Request']) {
  return JlinkRequest.request<ReqDeleteUploadFile>('uploads/delete', 'DELETE', data, true)
}

export function getUploadFileByQuery (data: ReqGetUploadFilesByQuery['Request']):Promise<TotalReturn<UploadFileWrapperVo>> {
  return JlinkRequest.request<ReqGetUploadFilesByQuery>('uploads/getByQuery', 'GET', data, true)
    .then((res) => {
      return { data: res.data.map(i => JlinkTransform.makeUploadFileWrapper(i)), totalCount: res.totalCount }
    })
}

export function getUploadSizeByQuery (data: ReqGetUploadsStatistics['Request']) {
  return JlinkRequest.request<ReqGetUploadsStatistics>('uploads/statistics', 'GET', data, true)
}
