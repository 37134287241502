import JlinkRequest from '@/common/global/JlinkRequest'

export function ossGet () {
  return JlinkRequest.request<ReqGetOssCredential>('system/getOssCredential', 'GET', undefined, false)
}

export function getDjiCloudPlatformInfo (data:ReqGetDjiCloudPlatformInfo['Request']) {
  return JlinkRequest.request<ReqGetDjiCloudPlatformInfo>('/dji/cloud/getDjiCloudInfo', 'GET', data, false)
}

export function getDjiApiInfo (data:ReqGetDjiApiInfo['Request']) {
  return JlinkRequest.request<ReqGetDjiApiInfo>('/dji/cloud/getDjiApiInfo', 'GET', data, false)
}

export function getMqtt (data:ReqGetMqtt['Request']) {
  return JlinkRequest.request<ReqGetMqtt>('/system/getMqttInfo', 'GET', data, false)
}
