import JlinkRequest from '@/common/global/JlinkRequest'

export function firmwareFlush (data: ReqFirmwareFlush['Request']) {
  return JlinkRequest.request<ReqFirmwareFlush>('firmware/flush', 'POST', data, true)
}

export function getFirmwareListByPage (data: ReqGetFirmwareListByPage['Request']) {
  return JlinkRequest.request<ReqGetFirmwareListByPage>('firmware/listByPage', 'GET', data, true)
}
export function getFirmwareListByType (data: ReqGetFirmwareListByType['Request']) {
  return JlinkRequest.request<ReqGetFirmwareListByType>('firmware/list', 'GET', data, true)
}

export function deviceUpgrade (data: ReqUpgrade['Request']) {
  return JlinkRequest.request<ReqUpgrade>('firmware/upgrade', 'POST', data, false)
}
