import JlinkRequest from '@/common/global/JlinkRequest'

export function getSenenMessageListByPage (data: ReqGetMessageListByPage['Request']) {
  return JlinkRequest.request<ReqGetMessageListByPage>('notify/getMessageListPageByToken', 'GET', data, false)
}

export function deleteSeneneMessage (data: ReqDeleteMessage['Request']) {
  return JlinkRequest.request<ReqDeleteMessage>('notify/deleteMessage', 'DELETE', data, false)
}

export function markSeneneMessage (data: ReqMarkMessage['Request']) {
  return JlinkRequest.request<ReqMarkMessage>('notify/markMessageRead', 'GET', data, false)
}
