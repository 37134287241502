import JlinkRequest from '@/common/global/JlinkRequest'

export function getBusinessPackageList (data: ReqGetBusinessPackageList['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqGetBusinessPackageList>('businessPackage/listByPage', 'GET', data, showLoading)
}

export function addBusinessPackage (data: ReqAddBusinessPackage['Request']) {
  return JlinkRequest.request<ReqAddBusinessPackage>('businessPackage/add', 'POST', data)
}

export function updateBusinessPackage (data: ReqUpdateBusinessPackage['Request']) {
  return JlinkRequest.request<ReqUpdateBusinessPackage>('businessPackage/update', 'PUT', data)
}

export function deleteBusinessPackage (data: ReqDeleteBusinessPackage['Request']) {
  return JlinkRequest.request<ReqDeleteBusinessPackage>('businessPackage/delete', 'DELETE', data)
}

export function refreshBusinessPackage (data: ReqRefreshBusinessPackage['Request']) {
  return JlinkRequest.request<ReqRefreshBusinessPackage>('businessPackage/refresh', 'GET', data)
}
