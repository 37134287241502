import { DATA_COORDINATE, MAP_COORDINATE } from '@/common/global/JlinkValues'
import JlinkUtils from '@/common/global/JlinkUtils'
import JlinkRequest from '@/common/global/JlinkRequest'

export function modifyDrone (data: ReqDroneUpdate['Request']) {
  return JlinkRequest.request<ReqDroneUpdate>('drone/modify', 'PUT', data)
}

export function getDroneListByPage (data: ReqGetDroneListByPage['Request']) {
  return JlinkRequest.request<ReqGetDroneListByPage>('drone/getListByPage', 'GET', data)
}

export function getDroneDetail (data: ReqGetDroneDetail['Request']) {
  return JlinkRequest.request<ReqGetDroneDetail>('drone/getDetail', 'GET', data)
}

export function getDroneListBaseAll (data: ReqGetDroneListBaseAll['Request']) {
  return JlinkRequest.request<ReqGetDroneListBaseAll>('drone/getListBaseAll', 'GET', data)
}

export function addDrone (data: ReqAddDrone['Request']) {
  return JlinkRequest.request<ReqAddDrone>('drone/add', 'POST', data)
}

export function deleteDrone (data: ReqDeleteDrone['Request']) {
  return JlinkRequest.request<ReqDeleteDrone>('drone/delete', 'DELETE', data)
}

export function uploadInsurance (data: ReqUploadDroneInsurance['Request']) {
  return JlinkRequest.request<ReqUploadDroneInsurance>('drone/uploadInsurance', 'POST', data)
}

export function updateInsurance (data: ReqUpdateDroneInsurance['Request']) {
  return JlinkRequest.request<ReqUpdateDroneInsurance>('drone/updateInsurance', 'PUT', data)
}

export function addSituationJournal (data: ReqAddDroneSituationJournal['Request']) {
  return JlinkRequest.request<ReqAddDroneSituationJournal>('drone/situation/addJournal', 'POST', data)
}

export function deleteSituationJournal (data: ReqDeleteDroneSituationJournal['Request']) {
  return JlinkRequest.request<ReqDeleteDroneSituationJournal>('drone/situation/deleteJournal', 'DELETE', data)
}

export function addSituation (data: ReqAddDroneSituation['Request']) {
  return JlinkRequest.request<ReqAddDroneSituation>('drone/addSituation', 'POST', data)
}

export function deleteSituation (data: ReqDeleteDroneSituation['Request']) {
  return JlinkRequest.request<ReqDeleteDroneSituation>('drone/deleteSituation', 'DELETE', data)
}

export function processDroneSituation (data: ReqProcessDroneSituation['Request']) {
  return JlinkRequest.request<ReqProcessDroneSituation>('drone/processSituation', 'PUT', data)
}

export function getPushUrl (data: ReqGetPushUrl['Request']) {
  return JlinkRequest.request<ReqGetPushUrl>('drone/getPushUrl', 'GET', data)
}

export function getDroneTrail (data: ReqGetDroneTrail['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReqGetDroneTrail>('drone/getDroneTrail', 'GET', data, showLoading)
    .then((res) => res.map((it) => JlinkUtils.coordinate.coordinateTransform({ lng: it.lng, lat: it.lat, height: it.height }, DATA_COORDINATE, MAP_COORDINATE)))
}

export function checkFirmware (data: ReqCheckFirmware['Request']) {
  return JlinkRequest.request<ReqCheckFirmware>('drone/checkFirmware', 'GET', data)
}

export function forceOnline (data: ReqForceOnline['Request']) {
  return JlinkRequest.request<ReqForceOnline>('drone/forceOnline', 'GET', data)
}
