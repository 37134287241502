import JlinkRequest from '@/common/global/JlinkRequest'

export function txGeocoder (data:ReGeocoder['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReGeocoder>('map/geocoder', 'GET', data, showLoading)
}

export function txGeocoder2 (data:ReGeocoder2['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<ReGeocoder2>('map/geocoder2', 'GET', data, showLoading)
}

export function txExplore (data:txExplore['Request'], showLoading: boolean = true) {
  return JlinkRequest.request<txExplore>('map/explore', 'GET', data, showLoading)
}
