import JlinkRequest from '@/common/global/JlinkRequest'
import {AxiosProgressEvent} from "axios";

// export function speakerTTSPlayStart (data: ReqSpeakerTTSPlayStart['Request']) {
//   return JlinkRequest.request<ReqSpeakerTTSPlayStart>('psdk/speakerTTSPlayStart', 'POST', data)
// }

export function inputBoxTextSet (data: ReqInputBoxTextSet['Request']) {
  return JlinkRequest.request<ReqInputBoxTextSet>('psdk/inputBoxTextSet', 'POST', data)
}

export function widgetValueSet (data: ReqWidgetValueSet['Request']) {
  return JlinkRequest.request<ReqWidgetValueSet>('psdk/widgetValueSet', 'POST', data)
}

export function speakerReplay (data: ReqSpeakerReplay['Request']) {
  return JlinkRequest.request<ReqSpeakerReplay>('psdk/speakerReplay', 'POST', data)
}

export function speakerPlayStop (data: ReqSpeakerPlayStop['Request']) {
  return JlinkRequest.request<ReqSpeakerPlayStop>('psdk/speakerPlayStop', 'POST', data)
}

export function speakerPlayModeSet (data: ReqSpeakerPlayModeSet['Request']) {
  return JlinkRequest.request<ReqSpeakerPlayModeSet>('psdk/speakerPlayModeSet', 'POST', data)
}

export function speakerPlayVolumeSet (data: ReqSpeakerPlayVolumeSet['Request']) {
  return JlinkRequest.request<ReqSpeakerPlayVolumeSet>('psdk/speakerPlayVolumeSet', 'POST', data)
}

export function psdkCustom (data: ReqPsdkCustom['Request']) {
  return JlinkRequest.request<ReqPsdkCustom>('psdk/custom', 'POST', data)
}

export function psdkPlayFileUpload (data: ReqPsdkPlayFileUpload['Request'], onUploadProgress?:(e:AxiosProgressEvent)=>void) {
  return JlinkRequest.request<ReqPsdkPlayFileUpload>('psdk/upload', 'POST', data, true, onUploadProgress)
}

export function psdkSpeakerPlay (data: ReqPsdkSpeakerPlay['Request']) {
  return JlinkRequest.request<ReqPsdkSpeakerPlay>('psdk/speakerPlay', 'POST', data)
}

export function psdkFileListByQuery (data: ReqPsdkFileListByQuery['Request']) {
  return JlinkRequest.request<ReqPsdkFileListByQuery>('psdk/listByQuery', 'GET', data)
}

export function psdkFileDelete (data: ReqPsdkFileDelete['Request']) {
  return JlinkRequest.request<ReqPsdkFileDelete>('psdk/delete', 'DELETE', data)
}

export function psdkFileRename (data: ReqPsdkFileRename['Request']) {
  return JlinkRequest.request<ReqPsdkFileRename>('psdk/rename', 'PUT', data)
}

export function psdkTtsFileUpload (data: ReqPsdkTtsFileUpload['Request']) {
  return JlinkRequest.request<ReqPsdkTtsFileUpload>('psdk/ttsToFile', 'POST', data)
}
