import JlinkRequest from '@/common/global/JlinkRequest'

export function zmTransform (data: ReqZmTransForm['Request']) {
  return JlinkRequest.request<ReqZmTransForm>('zhimou/transform', 'GET', data, true)
}
export function getApplications (data: ReqZmGetApplications['Request']) {
  return JlinkRequest.request<ReqZmGetApplications>('zhimou/getApplications', 'GET', data, true)
}
// export function zmEnd (data: ReqZmEnd['Request']) {
//   return JlinkWorker.axiosWorker.request<ReqZmEnd>('zhimou/end', 'GET', data, true)
// }
