import JlinkRequest from '@/common/global/JlinkRequest'

export function distanceLimitOpen (data:ReqDistanceLimitOpen['Request']) {
  return JlinkRequest.request<ReqDistanceLimitOpen>('property/distanceLimitOpen', 'PUT', data)
}

// 限远距离
export function distanceLimit (data:ReqDistanceLimit['Request']) {
  return JlinkRequest.request<ReqDistanceLimit>('property/distanceLimit', 'PUT', data)
}

// 飞行器限高
export function heightLimit (data:ReqHeightLimit['Request']) {
  return JlinkRequest.request<ReqHeightLimit>('property/heightLimit', 'PUT', data)
}

// 返航高度
export function rthAltitude (data:ReqRthAltitude['Request']) {
  return JlinkRequest.request<ReqRthAltitude>('property/rthAltitude', 'PUT', data)
}

// 遥控器失控动作
export function rcLostAction (data:ReqRcLostAction['Request']) {
  return JlinkRequest.request<ReqRcLostAction>('property/rcLostAction', 'PUT', data)
}

// 执行指点飞行时失控策略
export function commanderModeContinueWhenLost (data:ReqCommanderModeContinueWhenLost['Request']) {
  return JlinkRequest.request<ReqCommanderModeContinueWhenLost>('property/commanderModeContinueWhenLost', 'PUT', data)
}

// 执行指起飞点的高度
export function commanderFlightHeight (data:ReqCommanderFlightHeight['Request']) {
  return JlinkRequest.request<ReqCommanderFlightHeight>('property/commanderFlightHeight', 'PUT', data)
}

// 飞行器夜航灯状态
export function nightLightsStateOpen (data:ReqNightLightsStateOpen['Request']) {
  return JlinkRequest.request<ReqNightLightsStateOpen>('property/nightLightsStateOpen', 'PUT', data)
}

// 避障状态
export function obstacleAvoidance (data:ReqObstacleAvoidance['Request']) {
  return JlinkRequest.request<ReqObstacleAvoidance>('property/obstacleAvoidance', 'PUT', data)
}

// 设置调色盘
export function thermalCurrentPaletteStyle (data:ReqThermalCurrentPaletteStyle['Request']) {
  return JlinkRequest.request<ReqThermalCurrentPaletteStyle>('property/thermalCurrentPaletteStyle', 'PUT', data)
}

// 低增益模式
export function thermalGainMode (data:ReqThermalGainMode['Request']) {
  return JlinkRequest.request<ReqThermalGainMode>('property/thermalGainMode', 'PUT', data)
}

// 开启等温线
export function thermAlIsoThermStateOpen (data:ReqThermAlIsoThermStateOpen['Request']) {
  return JlinkRequest.request<ReqThermAlIsoThermStateOpen>('property/thermalisothermStateOpen', 'PUT', data)
}

// 测温区间上下限
export function thermAlIsoThermLimit (data:ReqThermAlIsoThermLimit['Request']) {
  return JlinkRequest.request<ReqThermAlIsoThermLimit>('property/thermalisothermLimit', 'PUT', data)
}
